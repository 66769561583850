import {Component} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {
  DashboardHeaderComponent
} from "../../layouts/dashboard-layout/components/dashboard-header/dashboard-header.component";
import {
  DashboardNavbarComponent
} from "../../layouts/dashboard-layout/components/dashboard-navbar/dashboard-navbar.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    RouterOutlet,
    DashboardHeaderComponent,
    DashboardNavbarComponent,
    NgIf
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {
  constructor() {}

}
