<div class="bg-gray-light rounded-2xl mx-4 my-6 p-4">
  <a href="dashboard/detail-work/{{ work.id }}">
    <div class="flex flex-col ml-2">
      <div class="flex justify-start text-2xl font-anton">
        {{web_job}}
      </div>
      <div *ngIf="event" class="flex justify-start text-lg font-anton">
        {{workplace}} - {{event.name}}
      </div>
      <div class="flex justify-start">
        {{location}}
      </div>
      <div class="flex justify-start items-center">
        <div class="mr-1 my-1">
          <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 9H21M17 13.0014L7 13M10.3333 17.0005L7 17M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        </div>
        <p *ngIf="start_date === end_date">
          {{start_date}}
        </p>
        <p *ngIf="start_date !== end_date">
          {{start_date}} a {{end_date}}
        </p>
      </div>
      <div class="flex justify-start text-2xl font-anton">
        {{salary}} €/hora
      </div>
      <div class="flex my-2 flex-wrap">
        <div *ngFor="let tag of tags" class="px-4 mb-1 border rounded-full border-gray-600 text-gray-600">{{tag.name}}</div>
      </div>
      <div class="flex justify-start items-center text-primary text-md font-bold">
        <div class="mr-1">
          <svg id="Grupo_60" data-name="Grupo 60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="15" viewBox="0 0 7.685 8.86">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectángulo_24" data-name="Rectángulo 24" width="7.685" height="8.86" fill="#31b968"/>
              </clipPath>
            </defs>
            <g id="Grupo_22" data-name="Grupo 22" clip-path="url(#clip-path)">
              <path id="Trazado_36" data-name="Trazado 36" d="M7.685,14.207H0V12.566a1.835,1.835,0,0,1,1.836-1.835H5.85a1.835,1.835,0,0,1,1.836,1.835Z" transform="translate(0 -5.347)" fill="#31b968"/>
              <path id="Trazado_37" data-name="Trazado 37" d="M7.675,2.215A2.215,2.215,0,1,0,5.46,4.43,2.215,2.215,0,0,0,7.675,2.215" transform="translate(-1.617 0)" fill="#31b968"/>
            </g>
          </svg>
        </div>
        <div class="mt-1">
          {{freeSlots}} PUESTOS DISPONIBLES
        </div>
      </div>
    </div>
  </a>

</div>
