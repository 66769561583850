import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {WebJob, Work} from "../../../models/Work";
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class WebJobService {

  constructor(private http: HttpClient) { }

  getWebJobs(): Observable<Response<WebJob[]>> {
    const url = `${environment.API_URL}/webjobs`;
    return this.http.get<Response<WebJob[]>>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }
}
