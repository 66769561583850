import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {UserV2} from "../../../models/UserV2";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  updateUser(userData: any): Observable<any> {
    const url = `${environment.API_URL}/user/update`;
    return this.http.post<Response<any>>(url, userData
    , {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }

  create(data: any) {
    const url = `${environment.API_URL}/user/create`;
    console.log('Final URL:', url);
    return this.http.post<Response<UserV2>>(url, data);
  }

  uploadFront(selectedFile: File, type: 'front'|'back'){
    const formData = new FormData();
    formData.append('image', selectedFile);
    const url = `${environment.API_URL}/user/dni/${type}`;
    return this.http.post<Response<any>>(url, formData
      , {});

  }

  uploadProfileImage(selectedFile: File, type: 'profile'){
    const formData = new FormData();
    formData.append('image', selectedFile);
    const url = `${environment.API_URL}/user/profile-image/${type}`;
    return this.http.post<Response<any>>(url, formData
      , {});

  }

  getProfileImage(userId:number){
    const url = `${environment.API_URL}/user/profile-image/${userId}`;
    return this.http.get<Response<any>>(url);
  }

  getFront(userId:number){
    const url = `${environment.API_URL}/user/dni-front/${userId}`;
    return this.http.get<Response<any>>(url);
  }

  getBack(userId:number){
    const url = `${environment.API_URL}/user/dni-back/${userId}`;
    return this.http.get<Response<any>>(url);
  }

  deleteFront(userId:number){
    const url = `${environment.API_URL}/user/dni-front/delete/${userId}`;
    return this.http.get<Response<any>>(url);
  }

  deleteBack(userId:number){
    const url = `${environment.API_URL}/user/dni-back/delete/${userId}`;
    return this.http.get<Response<any>>(url);
  }

  deleteProfileImage(userId:number){
    const url = `${environment.API_URL}/user/profile-image/delete/${userId}`;
    return this.http.get<Response<any>>(url);
  }

  getContracts(userId:number){
    const url = `${environment.API_URL}/user/contracts/${userId}`;
    return this.http.get<Response<any>>(url);
  }

  getContractFile(userId: number, contractName: string): Observable<any> {
    const url = `${environment.API_URL}/user/contracts/${userId}/${contractName}`;
    return this.http.get<any>(url); // Ajusta el tipo de retorno a 'any'
  }

}
