import { Directive } from '@angular/core';

@Directive({
  selector: '[appAuthFormLightLabel]',
  host: {
    'class': 'text-sm text-gray-light pl-2',
  },
  standalone: true
})
export class AuthFormLightLabelDirective {

  constructor() { }

}
