import { Component, OnInit } from '@angular/core';
import {WorkService} from "../../../../core/services/works/work.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Response} from "../../../../models/Response";
import {Tag, Work} from "../../../../models/Work";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {Shift} from "../../../../models/Shift";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {ToastrService} from "ngx-toastr";
import {ShiftService} from "../../../../core/services/shifts/shift.service";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-detail-work',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    LoaderComponent,
    NgClass,

  ],
  templateUrl: './detail-work.component.html',
  styleUrl: './detail-work.component.css'
})
export class DetailWorkComponent implements OnInit{
  workId!: number;
  work!: Work | undefined;
  start_date: string | null = null;
  end_date: string |null = null;
  shifts!: Shift[] | undefined;
  userShifts!: Shift[] | undefined;
  userId!: number | undefined;
  loading:boolean = false;
  tags!: Tag[] | undefined;
  public userShiftInWork: boolean = false;
  sanitizedMapIframe!: SafeHtml;
  public userWorkApplicated: boolean = false;
  workApplications: Work[] | undefined = [];
  userShiftsApplications: Shift[] = [];
  public showButton: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private workService: WorkService,
    private shiftService:ShiftService,
    private authStateService: AuthStateService,
    private toastrService: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(){
    window.scrollTo(0, 0);
    this.loading = true;
    this.workId = Number(this.route.snapshot.paramMap.get('id'));
    this.userId = this.authStateService.currentUserValue?.profile.id;
    this.workService.getWork(this.workId).subscribe({
      next: (response: Response<Work>) => {
        this.work = response.data;
      },
      error: (err: any) => {
        console.error('Error al obtener los trabajos:', err);
      },
      complete: () => {
        this.loading = false;
        this.work?.free_shifts.forEach((shift) => {
          const startDate = new Date(shift.start_hour).toISOString().split('T')[0];
          const endDate = new Date(shift.end_hour).toISOString().split('T')[0];

          if (this.start_date === null || startDate < this.start_date) {
            this.start_date = startDate;
          }

          if (this.end_date === null || endDate > this.end_date) {
            this.end_date = endDate;
          }
        });

        this.start_date = this.start_date ? this.formatDate(this.start_date) : 'No disponible';
        this.end_date = this.end_date ? this.formatDate(this.end_date) : 'No disponible';
        this.tags = this.work?.tags;
        this.shifts = this.work?.free_shifts;

        if (this.work) {
          let iframeHtml = this.work.workplace.map_iframe;

          // Modifica el código del iframe para ajustar el ancho y la altura
          iframeHtml = this.modifyIframeSize(iframeHtml, '100%', '100%', 1);

          // Saneamos el HTML antes de insertarlo en el DOM
          this.sanitizedMapIframe = this.sanitizer.bypassSecurityTrustHtml(iframeHtml);
        }
      }
    });

    if(this.userId){

      forkJoin({
        userShifts: this.shiftService.nextShifts(this.userId),
        userWorkApplications: this.workService.getWorkApplications(this.userId)
      }).subscribe({
        next: ({ userShifts, userWorkApplications }) => {
          if (userShifts.data) {
            this.userShifts = userShifts.data;
          }
          if (userWorkApplications.data) {
            this.workApplications = userWorkApplications.data;
            this.workApplications.forEach((work: Work) => {
              this.userShiftsApplications = [...this.userShiftsApplications, ...work.shifts];
            });
          }
          this.loading = false;
          this.showButton = true;
        },
        error: error => {
          this.loading = false;
          console.error('Error al obtener los turnos:', error);

        }
      });
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const dayOfMonth = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];

    return `${dayOfMonth} de ${month}`;
  }

  formatHour(dateString: string): string {
    const date = new Date(dateString);

    // Obtiene las horas y minutos
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Devuelve en formato 24 horas
    return `${hours}:${minutes}`;
  }

  applyToWork() {
    if (this.userId) {
      this.workService.applyToWork(this.userId, this.workId).subscribe({
        next: (response) => {
          if (response.success) {
            this.toastrService.success(response.message);
            this.router.navigate(['/dashboard/works']);
          } else {
            this.toastrService.error(response.message);
          }
        },
        error: (err) => {
          this.toastrService.error(err.error.message);
        }
      });
    } else {
      this.toastrService.error('Hubo un error al aplicar al trabajo. Inténtalo de nuevo más tarde.');
    }
  }

  modifyIframeSize(iframeHtml: string, newWidth: string, newHeight: string, zoomLevel: number): string {
    // Reemplaza el ancho y la altura del iframe en el código HTML
    iframeHtml = iframeHtml.replace(/width="[^"]*"/, `width="${newWidth}"`);
    iframeHtml = iframeHtml.replace(/height="[^"]*"/, `height="${newHeight}"`);
    iframeHtml = iframeHtml.replace(/(zoom=\d+)/, `zoom=${zoomLevel}`);

    return iframeHtml;
  }

  checkUserShiftsInWork() {
    this.loading = true;
    this.userWorkApplicated = false;

    if (this.userShifts) {
      for (const userShift of this.userShifts) {
        if (userShift.work_id === this.workId) {
          this.userWorkApplicated = true;
          break; // Sal del bucle una vez que encuentres un match
        }
      }
    }

    this.loading = false;
    return this.userWorkApplicated;
  }

  checkUserWorkApplicated() {
    this.loading = true;
    this.userShiftInWork = false;

    if (this.userShiftsApplications) {
      for (const userShift of this.userShiftsApplications) {
        if (userShift.work_id === this.workId) {
          this.userShiftInWork = true;
          break; // Sal del bucle una vez que encuentres un match
        }
      }
    }

    this.loading = false;
    return this.userShiftInWork;
  }
}
