<div class="m-4 cursor-pointer transition-all duration-300 text-gray-light">
  <div class="flex justify-between p-2">
    <p class="text-sm pl-2 font-bold">{{ date }}</p>
  </div>
  <div class="my-1 py-2 px-4 rounded-2xl bg-gray-700">
    <p class="text-lg font-bold">{{ web_job }} - {{workplace}}</p>
    <p *ngIf="!pastShift" class="mt-2 text-lg font-bold">{{ start_hour }} - {{ end_hour }}</p>
    <p *ngIf="pastShift" class="mt-2 text-lg font-bold">{{real_start_hour}} - {{real_end_hour}}</p>
    <p *ngIf="pastShift" class="mt-2 text-lg font-bold">{{workedHours}}</p>
  </div>
</div>
