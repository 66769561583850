import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { RouterLink, RouterLinkActive } from "@angular/router";
import { UserService } from "../../../../core/services/users/user.service";
import { ToastrService } from "ngx-toastr";
import { AuthFormInputDirective } from "../../../../shared/directives/auth/auth-form-input.directive";
import { AuthFormLabelDirective } from "../../../../shared/directives/auth/auth-form-label.directive";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgForOf, NgIf } from "@angular/common";
import { AuthStateService } from "../../../../core/services/auth/auth-state.service";
import { User } from "../../../../models/User";
import { LoaderComponent } from "../../../../shared/components/loader/loader.component";

@Component({
  selector: 'app-dni',
  standalone: true,
  imports: [
    RouterLink,
    AuthFormInputDirective,
    AuthFormLabelDirective,
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    RouterLinkActive,
    NgIf,
    LoaderComponent
  ],
  templateUrl: './dni.component.html',
  styleUrls: ['./dni.component.css']
})
export class DniComponent implements OnInit {

  currentUser: User | null = null;
  loadingFront: boolean = false;
  loadingBack: boolean = false;
  frontImage: any;
  backImage: any;
  dniForm: FormGroup;

  constructor(private userService: UserService, private authStateService: AuthStateService, private toastr: ToastrService) {
    this.dniForm = new FormGroup({
      dni: new FormControl('', [Validators.required]),
      dni_expiration: new FormControl('', [])
    });
  }

  ngOnInit() {
    this.currentUser = this.authStateService.currentUserValue;
    if (this.currentUser && this.currentUser.profile) {
      this.dniForm.patchValue({
        dni: this.currentUser.profile.dni,
        dni_expiration: this.currentUser.profile.dni_expiration,
      });
    }
    this.fetchImages();
  }

  fetchFront(){
    this.loadingFront = true;

    if (this.currentUser && this.currentUser.profile && this.currentUser.profile.media) {
      // Filtra la colección para encontrar la imagen frontal del DNI
      const frontMedia = this.currentUser.profile.media.find((mediaItem: any) => mediaItem.collection_name === 'dni-front');

      if (frontMedia) {
        this.frontImage = frontMedia.original_url;
        console.log(this.currentUser.profile);
      } else {
        this.userService.getFront(this.currentUser.profile.id).subscribe({
          next: (data) => {
            if (data.success) {
              this.frontImage = data.data.original_url;
              this.currentUser!.profile.media.push(data.data);
              console.log(this.currentUser?.profile);
            }
          },
          error: (err) => {
            this.toastr.error('Error al obtener la imagen delantera');
          },
          complete: () => {
            this.loadingFront = false;
          }
        });
      }
    } else {
      this.toastr.error('No se pudo cargar el perfil del usuario.');
    }
    this.loadingFront = false;
  }

  fetchBack(){
    this.loadingBack = true;

    if (this.currentUser && this.currentUser.profile && this.currentUser.profile.media) {
      // Filtra la colección para encontrar la imagen frontal del DNI
      const backMedia = this.currentUser.profile.media.find((mediaItem: any) => mediaItem.collection_name === 'dni-back');

      if (backMedia) {
        this.backImage = backMedia.original_url;
        console.log(this.currentUser.profile);
      } else {
        this.userService.getBack(this.currentUser.profile.id).subscribe({
          next: (data) => {
            if (data.success) {
              this.backImage = data.data.original_url;
              this.currentUser!.profile.media.push(data.data);
              console.log(this.currentUser?.profile);
            }
          },
          error: (err) => {
            this.toastr.error('Error al obtener la imagen trasera');
          },
          complete: () => {
            this.loadingBack = false;
          }
        });
      }
    } else {
      this.toastr.error('No se pudo cargar el perfil del usuario.');
    }
    this.loadingBack = false;
  }

  fetchImages() {
    if (this.currentUser && this.currentUser.profile) {
      this.fetchFront()
      this.fetchBack()
    }
  }

  deleteFront() {
    if (this.currentUser && this.currentUser.profile && this.currentUser.profile.media) {
      // Filtra la imagen frontal del DNI
      const frontMediaIndex = this.currentUser.profile.media.findIndex((mediaItem: any) => mediaItem.collection_name === 'dni-front');

      if (frontMediaIndex !== -1) {

        this.userService.deleteFront(this.currentUser.profile.id).subscribe({
          next: (data) => {
            if (data.success) {
              // Eliminar la imagen del array `media`
              this.currentUser!.profile.media.splice(frontMediaIndex, 1);
              this.frontImage = null; // Limpia la imagen mostrada
              this.toastr.success(data.message);
            } else {
              this.toastr.error(data.message);
            }
          },
          error: (err) => {
            this.toastr.error(err.error.message);
          },
          complete: () => {
            console.log(this.currentUser?.profile);
          }
        });
      } else {
        this.toastr.warning('No se encontró la imagen frontal del DNI para eliminar.');
      }
    } else {
      this.toastr.error('No se pudo cargar el perfil del usuario.');
    }
  }

  deleteBack() {
    if (this.currentUser && this.currentUser.profile && this.currentUser.profile.media) {
      // Filtra la imagen frontal del DNI
      const backMediaIndex = this.currentUser.profile.media.findIndex((mediaItem: any) => mediaItem.collection_name === 'dni-back');

      if (backMediaIndex !== -1) {

        this.userService.deleteFront(this.currentUser.profile.id).subscribe({
          next: (data) => {
            if (data.success) {
              // Eliminar la imagen del array `media`
              this.currentUser!.profile.media.splice(backMediaIndex, 1);
              this.backImage = null; // Limpia la imagen mostrada
              this.toastr.success(data.message);
            } else {
              this.toastr.error(data.message);
            }
          },
          error: (err) => {
            this.toastr.error(err.error.message);
          },
          complete: () => {
            console.log(this.currentUser?.profile);
          }
        });
      } else {
        this.toastr.warning('No se encontró la imagen trasera del DNI para eliminar.');
      }
    } else {
      this.toastr.error('No se pudo cargar el perfil del usuario.');
    }
  }

  saveClick() {
    const data: any = {
      user_id: this.currentUser?.profile.id,
      name: this.currentUser?.profile.name,
      email: this.currentUser?.profile.email,
      phone: this.currentUser?.profile.phone,
      address: this.currentUser?.profile.address,
      province: this.currentUser?.profile.province,
      dni: this.dniForm.get('dni')?.value,
      dni_expiration: this.dniForm.get('dni_expiration')?.value,
    };

    this.userService.updateUser(data).subscribe({
      next: (data) => {
        this.authStateService.setProfile(data.user);
        this.toastr.success('DNI actualizado con éxito.');
      },
      error: (err) => {
        this.toastr.error(err.error.message);
      }
    });
  }

  onFileChange(event: any, type: 'front' | 'back') {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      if (file) {
        if (type === 'front') {
          this.loadingFront = true;
        } else {
          this.loadingBack = true;
        }

        this.userService.uploadFront(file, type).subscribe({
          next: () => {
            this.toastr.success('Subido correctamente');
            if(type === "front"){
              this.fetchFront()
            } else {
              this.fetchBack()
            }
          },
          error: (err) => {
            this.toastr.error('Error al subir');
          },
          complete: () => {
            if (type === 'front') {
              this.loadingFront = false;
            } else {
              this.loadingBack = false;
            }
          }
        });
      }
    }
  }

  checkChanges(){
    if(this.currentUser && this.dniForm){
      return (this.dniForm.get('dni')?.value !== this.currentUser.profile.dni || this.dniForm.get('dni_expiration')?.value !== this.currentUser.profile.dni_expiration);
    }
    return true;
  }
}
