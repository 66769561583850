import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { User } from '../../../models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  private localStorageKey = 'ett_user';
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;
  profileUpdated = new Subject<void>();

  constructor() {
    const storedUser = localStorage.getItem(this.localStorageKey);
    this.currentUserSubject = new BehaviorSubject<User | null>(storedUser ? JSON.parse(storedUser) : null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  public isLoggedIn(){
    return !!this.currentUserSubject.value;
  }

  public setUser(user: User | null): void {
    if (user) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(user));
    } else {
      localStorage.removeItem(this.localStorageKey);
    }
    this.currentUserSubject.next(user);
    this.profileUpdated.next(); // Notificar que el usuario ha cambiado
  }

  public setProfile(userProfile: any) {
    const currentUser = this.currentUserSubject.value;
    if (currentUser) {
      currentUser.profile = {
        ...currentUser.profile,
        ...userProfile
      };

      this.setUser(currentUser);
    }
  }

}
