import {Component, OnInit} from '@angular/core';
import {Shift} from "../../../../models/Shift";
import {ShiftService} from "../../../../core/services/shifts/shift.service";
import {User} from "../../../../models/User";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {Response} from "../../../../models/Response";
import {NgForOf, NgIf} from "@angular/common";
import {ShiftCardComponent} from "../../../../cards/shift-card/shift-card.component";
import {NextShiftCardComponent} from "../../../../cards/next-shift-card/next-shift-card.component";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";

@Component({
  selector: 'app-my-shifts',
  standalone: true,
  imports: [
    NgForOf,
    ShiftCardComponent,
    NgIf,
    NextShiftCardComponent,
    LoaderComponent
  ],
  templateUrl: './my-shifts.component.html',
  styleUrl: './my-shifts.component.css'
})
export class MyShiftsComponent implements OnInit{
  shifts!: Shift[] | undefined;
  currentUser: User | null = null;
  isDropdownOpen: boolean = false;
  loading:boolean = false;
  pastShift: boolean = false;

  constructor(
    private shiftService: ShiftService,
    private authStateService: AuthStateService
) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loading = true;
    this.currentUser = this.authStateService.currentUserValue;
    this.shiftService.nextShifts(this.currentUser!.profile.id).subscribe({
      next: (response: Response<Shift[]>) => {
        this.shifts = response.data;
        this.loadNextShifts();
      },
      error: (err) => {
        console.error('Error al obtener los turnos:', err);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  loadNextShifts(): void {
    if (this.currentUser) {
      this.loading = true;
      this.pastShift = false;
      this.shiftService.nextShifts(this.currentUser.profile.id).subscribe({
        next: (response: Response<Shift[]>) => {
          this.shifts = response.data;
        },
        error: (err) => {
          console.error('Error al obtener los turnos:', err);
        },
        complete: () => {
          this.loading = false;
        }
      });
    }
    this.isDropdownOpen = false; // Cerrar el desplegable después de la selección
  }

  loadPreviousShifts(): void {
    if (this.currentUser) {
      this.loading = true;
      this.pastShift = true;
      this.shiftService.getUserShifts(this.currentUser.profile.id).subscribe({
        next: (response: Response<Shift[]>) => {
          this.shifts = response.data;
        },
        error: (err) => {
          console.error('Error al obtener los turnos:', err);
        },
        complete: () => {
          this.loading = false;
        }
      });
    }
    this.isDropdownOpen = false;
  }

  onShiftUpdated() {
    this.loadNextShifts();
  }

}
