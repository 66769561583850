import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {WebJob, Work, Workplace} from "../../../../../models/Work";
import {WorkService} from "../../../../../core/services/works/work.service";
import {FormsModule} from "@angular/forms";
import {NgForOf} from "@angular/common";
import {Response} from "../../../../../models/Response";
import {WebJobService} from "../../../../../core/services/webJobs/web-job-service.service";

@Component({
  selector: 'app-work-filter',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf
  ],
  templateUrl: './work-filter.component.html',
  styleUrl: './work-filter.component.css'
})
export class WorkFilterComponent implements OnInit {

  webJobs: WebJob[] | undefined = undefined;
  workplaces: Workplace[] = [];

  selectedWebJob: WebJob | null = null;
  selectedWorkplace: Workplace | null = null;

  @Output() filterChanged: EventEmitter<{ webJob: WebJob | null /*, workplace: Workplace | null*/ }> = new EventEmitter();

  constructor(private webJobService: WebJobService,) { }

  ngOnInit(): void {
    this.webJobService.getWebJobs().subscribe({
      next: (response: Response<WebJob[]>) => {
        this.webJobs = response.data;
      },
      error: (err) => {
        console.error('Error al obtener los puestos de trabajo:', err);
      },
      complete: () => {
        //console.log(this.works)
      }
    });
  }

  filterJobs(): void {
    this.filterChanged.emit({
      webJob: this.selectedWebJob || null
      // Puedes agregar workplace aquí si lo necesitas más adelante
    });
  }
}
