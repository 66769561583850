<div class="m-4 cursor-pointer transition-all duration-300 p-2 rounded-2xl bg-white text-gray-dark shadow-lg">
  <div class="flex justify-between p-2">
    <p class="text-sm pl-4 font-bold">{{ date }}</p>
    <div>
      <p *ngIf="shiftStatus === 'accepted'" class="px-3 py-1 bg-secondary text-gray-dark font-semibold text-sm rounded-full">Próximamente</p>
      <p *ngIf="shiftStatus === 'working'" class="px-3 py-1 bg-green-500 text-gray-dark font-semibold text-sm rounded-full">Trabajando</p>
    </div>
  </div>
  <div class="text-gray-dark mx-6 bg-white">
    <p class="text-lg font-bold text-center">{{ web_job }} - {{workplace}}</p>
    <p class="mt-2 text-3xl font-roboto font-semibold text-center">{{ start_hour }} - {{ end_hour }}</p>
    <p class="mb-4 text-sm text-center font-semibold">{{location}}</p>
    <div class="flex justify-center">
      <button (click)="toggleShift()"
        class="my-2 bg-secondary font-semibold text-gray-dark px-4 py-2 rounded-full w-5/6 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</div>
