<section class="font-roboto bg-bg-dark text-gray-light animate-fade-in">
  <div class="px-4 py-8 mx-auto h-screen lg:py-0">
    <div class="w-full md:mt-0 sm:max-w-lg xl:p-0 animate-fade-in-up">
      <div class="pb-16 space-y-4 md:space-y-6 sm:p-8">
        <div class="p-4 bg-gray-700 shadow-lg rounded-lg text-gray-light">
          <p>
            Aquí encontrarás los contratos laborales de las empresas en las que has trabajado o estás trabajando en la actualidad.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
