import { Component } from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-dashboard-navbar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NgIf
  ],
  templateUrl: './dashboard-navbar.component.html',
  styleUrl: './dashboard-navbar.component.css'
})
export class DashboardNavbarComponent {
  public icon: string = 'shifts';
  constructor(private router: Router) {}
  ngOnInit() {
    this.updateTitle(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateTitle(event.urlAfterRedirects);
      }
    });
  }

  updateTitle(url: string) {
    if (url.includes('/works')) {
      this.icon = 'works';
    } else if (url.includes('/my-shifts')) {
      this.icon = 'shifts';
    } else if (url.includes('/profile')) {
      this.icon = 'profile';
    } else {
      this.icon = '';
    }
  }
}
