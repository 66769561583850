<section class="bg-bg-dark h-screen animate-fade-in">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
    <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-lg xl:p-0 animate-fade-in-up">
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <p class="text-gray-800 font-semibold text-center">Cambio de contraseña</p>
        <form class="space-y-4 md:space-y-6">
          <div>
            <label for="password" appAuthFormLabel>Contraseña</label>
            <input [(ngModel)]="password" type="password" name="email" id="password" appAuthFormInput placeholder="*********" required>
          </div>
          <div>
            <label for="password_confirmation" appAuthFormLabel>Confirmar contraseña</label>
            <input [(ngModel)]="password_confirmation" type="password" name="email" id="password_confirmation" appAuthFormInput placeholder="*********" required>
          </div>
          <button (click)="resetPassword()" type="submit" class="w-full disabled:bg-darkGray text-white bg-primary hover:opacity-85 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
            Recuperar
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
