import { Directive } from '@angular/core';

@Directive({
  selector: '[appAuthFormInput]',
  host: {
    'class': 'rounded-lg border-b border-lightGray bg-gray-dark outline-none text-gray-light font-semibold block w-full p-2.5'
  },
  standalone: true
})
export class AuthFormInputDirective {

  constructor() { }

}
