import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {Shift} from "../../../models/Shift";
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Work} from "../../../models/Work";

@Injectable({
  providedIn: 'root'
})
export class WorkService {

  constructor(private http: HttpClient) { }

  getFreeWorks(): Observable<Response<Work[]>> {
    const url = `${environment.API_URL}/works/freeWorks`;
    return this.http.get<Response<Work[]>>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }

  getWorkApplications(userId: number): Observable<Response<Work[]>> {
    const url = `${environment.API_URL}/works/workApplications`;
    return this.http.get<Response<Work[]>>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { user_id: userId }
    });
  }

  getWebJobFreeWorks(webJobId: number): Observable<Response<Work[]>> {
    const url = `${environment.API_URL}/works/WebJobFreeWorks`;
    return this.http.get<Response<Work[]>>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { web_job_id: webJobId }
    });
  }

  getWork(workId: number): Observable<Response<Work>> {
    const url = `${environment.API_URL}/works/work`;
    return this.http.get<Response<Work>>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { work_id: workId.toString() }
    });
  }

  applyToWork(userId: number, workId: number): Observable<Response<any>> {
    const url = `${environment.API_URL}/works/applyToWork`;

    // Enviar un objeto en lugar de un array
    return this.http.post<Response<any>>(url,
      {
        user_id: userId,
        work_id: workId
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    );
  }
}
