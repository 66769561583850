<div class="flex text-sm">
  <p class="mx-4 text-primary font-roboto font-light">Filtrar:</p>
  <div class="flex w-full items-center">
    <label for="webJob" class="text-gray-light mr-2">Puesto:</label>
    <select id="webJob" [(ngModel)]="selectedWebJob" (change)="filterJobs()" class="rounded-lg w-44">
      <option value="">Todos</option>
      <option *ngFor="let webJob of webJobs" [ngValue]="webJob">{{ webJob.name }}</option>
    </select>
  </div>
  <!--<div class="mx-4">
    <label for="company" class="text-gray-light mr-2">Empresa:</label>
    <select id="company" [(ngModel)]="selectedWorkplace" (change)="filterJobs()" class="rounded-lg">
      <option value="">Todas</option>
      <option *ngFor="let workplace of workplaces" [value]="workplace">{{ workplace.name }}</option>
    </select>
  </div>-->
</div>
