<div class="bg-bg-dark">
  <div class="fixed top-16 w-full flex justify-between items-center bg-bg-dark p-4 text-sm">
    <button (click)="toggleDropdown()" class="px-3 py-1 bg-bg-dark text-white rounded-full border border-gray-light">
      Trabajos
    </button>
    <div *ngIf="isDropdownOpen" class="absolute left-4 mt-0 w-fit bg-bg-dark border border-gray-light rounded-lg shadow-lg">
      <button (click)="loadWorks()" class="block px-3 py-1 w-fit text-left text-gray-light hover:bg-gray-100">
        Ofertas de trabajo
      </button>
      <button (click)="loadWorkApplications()" class="block px-3 py-1 text-left text-gray-light hover:bg-gray-100">
        Mis inscripciones
      </button>
    </div>
    <app-work-filter class="bg-bg-dark" (filterChanged)="onFilterChanged($event)"></app-work-filter>
  </div>
  <div class="pt-16">
    <app-loader size="large" *ngIf="loading"></app-loader>
    <h3 *ngIf="!loading" class="bg-bg-dark text-primary px-4 py-2 text-start text-xl font-anton">{{currentTitle}}</h3>
    <app-work-card *ngFor="let work of works" [work]="work"></app-work-card>
  </div>
</div>
