import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxSpinnerModule} from "ngx-spinner";
import {provideToastr} from "ngx-toastr";
import {AddBearerTokenInterceptor} from "./core/interceptors/add-bearer-token.interceptor";
import {AuthInterceptor} from "./core/interceptors/auth.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    importProvidersFrom([BrowserAnimationsModule, HttpClientModule, NgxSpinnerModule]),
    provideToastr({
      autoDismiss: true,
      positionClass: 'toast-top-right',
      closeButton: true
    }),
    provideRouter(routes),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddBearerTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
};
