<section class="font-roboto bg-bg-dark h-screen animate-fade-in">
  <div class="px-4 mx-auto md:h-screen lg:py-0">
    <div class="p- mt-6 flex justify-end">
      <a routerLink="/dashboard/profile" class="text-primary font-roboto text-md">Volver</a>
    </div>
    <div class="w-full md:mt-0 sm:max-w-lg xl:p-0 animate-fade-in-up">
      <div class="py-4 space-y-4 sm:p-8">
        <form [formGroup]="dniForm" class="flex flex-col gap-y-4">
          <div class="flex flex-col gap-y-4">
            <div>
              <p class="text-gray-light">Número de documento</p>
              <input formControlName="dni" type="text" placeholder="000 000 000" appAuthFormInput>
            </div>
            <div>
              <p class="text-gray-light">Fecha de expiración del documento</p>
              <input formControlName="dni_expiration" appAuthFormInput placeholder="00/00/00">
            </div>
          </div>

          <div class="flex flex-col gap-y-2">
            <!-- Contenedor para la parte delantera del documento -->
            <div class="flex flex-col bg-dark-gray p-2 rounded-lg">
              <span class="text-gray-light mb-2">Fotografía del documento (parte delantera)</span>
              <hr class="w-full text-secondaryGray mb-4">

              <!-- Mostrar el loader para la imagen delantera si loadingFront es verdadero -->
              <div *ngIf="loadingFront" class="flex justify-center items-center h-36">
                <app-loader></app-loader>
              </div>
              <div *ngIf="!loadingFront">
                <div class="flex relative" *ngIf="frontImage; else uploadFront">
                  <img [src]="frontImage" alt="Parte Delantera del Documento" class="w-full h-36 object-cover rounded-2xl mb-4 brightness-100">
                  <svg (click)="deleteFront()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 text-primary cursor-pointer right-1 top-1 absolute drop-shadow-2xl">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </div>

                <!-- Mostrar opción de subida si no hay imagen -->
                <ng-template #uploadFront class="w-full">
                  <label for="front-file-upload" class="cursor-pointer flex flex-col justify-center items-center w-full h-36 bg-secondaryGray rounded-2xl hover:bg-gray-600 transition duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mb-2 w-10 h-10 text-gray-light">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                      <path d="M9 13a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                    </svg>
                    <span class="text-gray-light">Subir foto</span>
                    <input id="front-file-upload" type="file" (change)="onFileChange($event, 'front')" class="hidden"/>
                  </label>
                </ng-template>
              </div>
            </div>

            <!-- Contenedor para la parte trasera del documento -->
            <div class="flex flex-col bg-dark-gray p-2 rounded-lg ">
                <span class="text-gray-light mb-2">Fotografía del documento (parte trasera)</span>
              <hr class="w-full text-secondaryGray mb-4">

              <!-- Mostrar el loader para la imagen trasera si loadingBack es verdadero -->
              <div *ngIf="loadingBack" class="flex justify-center items-center h-36">
                <app-loader></app-loader>
              </div>
              <div *ngIf="!loadingBack">
                <div class="flex relative" *ngIf="backImage; else uploadBack">
                  <img [src]="backImage" alt="Parte Trasera del Documento" class="w-full h-36 object-cover rounded-2xl mb-4 brightness-100">
                  <svg (click)="deleteBack()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 text-primary cursor-pointer right-1 top-1 absolute drop-shadow-2xl">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </div>

                <!-- Mostrar opción de subida si no hay imagen -->
                <ng-template #uploadBack>
                  <label for="back-file-upload" class="cursor-pointer flex flex-col justify-center items-center w-full h-36 bg-secondaryGray rounded-2xl hover:bg-gray-600 transition duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mb-2 w-10 h-10 text-gray-light">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                      <path d="M9 13a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                    </svg>
                    <span class="text-gray-light">Subir foto</span>
                    <input id="back-file-upload" type="file" (change)="onFileChange($event, 'back')" class="hidden"/>
                  </label>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="flex justify-center">
            <a *ngIf="checkChanges()" routerLink="/dashboard/profile" class="flex justify-center items-center rounded-full w-fit px-16 py-2 text-center bg-primary text-gray-dark disabled:bg-secondary disabled:text-secondaryGray hover:shadow-lg cursor-pointer" (click)="saveClick()" >Confirmar</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
