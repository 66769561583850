import { Routes } from '@angular/router';
import {LoginComponent} from "./pages/auth/login/login.component";
import {loginGuard} from "./core/guards/login.guard";
import {ResetPasswordComponent} from "./pages/auth/reset-password/reset-password.component";
import {ForgotPasswordComponent} from "./pages/auth/forgot-password/forgot-password.component";
import {RegisterComponent} from "./pages/auth/register/register.component";
import {authGuard} from "./core/guards/auth.guard";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {MyShiftsComponent} from "./pages/dashboard/pages/my-shifts/my-shifts.component";
import {ProfileComponent} from "./pages/dashboard/pages/profile/profile.component";
import {WorksComponent} from "./pages/dashboard/pages/works/works.component";
import {DniComponent} from "./pages/dashboard/pages/dni/dni.component";
import {DetailWorkComponent} from "./pages/dashboard/pages/detail-work/detail-work.component";
import {PersonalDataComponent} from "./pages/dashboard/pages/personal-data/personal-data.component";
import {ContractsComponent} from "./pages/dashboard/pages/contracts/contracts.component";
import {DocumentsComponent} from "./pages/dashboard/pages/documents/documents.component";
import {DocumentsToSignComponent} from "./pages/dashboard/pages/documents-to-sign/documents-to-sign.component";

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'reset-password/:token/:email',
    component: ResetPasswordComponent,
    canActivate: [loginGuard]
  },
  {
    path: 'olvidaste-tu-contrasena',
    component: ForgotPasswordComponent,
    canActivate: [loginGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'works',
        component: WorksComponent
      },
      {
        path: 'detail-work/:id',
        component: DetailWorkComponent
      },
      {
        path: 'my-shifts',
        component: MyShiftsComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'dni',
        component: DniComponent
      },
      {
        path: 'personal-data',
        component: PersonalDataComponent
      },
      {
        path: 'contracts',
        component: ContractsComponent
      },
      {
        path: 'documents',
        component: DocumentsComponent
      },
      {
        path: 'documents-to-sign',
        component: DocumentsToSignComponent
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'my-shifts'
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'my-shifts'
      }

      ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login'
  },
];
