import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Shift} from "../../models/Shift";
import {NgClass, NgIf} from "@angular/common";
import {ShiftService} from "../../core/services/shifts/shift.service";
import {User} from "../../models/User";
import {AuthStateService} from "../../core/services/auth/auth-state.service";

@Component({
  selector: 'app-shift-card',
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ],
  templateUrl: './shift-card.component.html',
  styleUrl: './shift-card.component.css'
})
export class ShiftCardComponent implements OnInit{
  @Input() shift!: Shift;
  date!: string;
  start_hour!: string;
  end_hour!: string;
  real_start_hour!: string;
  real_end_hour!: string;
  web_job!: string;
  location!: string;
  workplace!: string;
  currentUser: User | null = null;
  workedHours!: string;
  @Input() pastShift!: boolean;

  constructor(
    private authStateService: AuthStateService
  ) {}

  ngOnInit() {
    this.currentUser = this.authStateService.currentUserValue;

    const startDate = new Date(this.shift.start_hour);
    const endDate = new Date(this.shift.end_hour);

    this.date = this.formatDate(startDate);

    this.start_hour = this.formatTime(startDate);
    this.end_hour = this.formatTime(endDate);

    this.workplace = this.shift.work.workplace.name;
    this.web_job = this.shift.work.web_job.name;
    this.location = this.shift.work.workplace.location;

    if (this.pastShift) {
      this.calculateWorkedHours();
    }
  }

  calculateWorkedHours(): void {
    if (this.shift.real_start_hour && this.shift.real_end_hour) {
      this.real_start_hour = this.formatTime(new Date(this.shift.real_start_hour));
      this.real_end_hour = this.formatTime(new Date(this.shift.real_end_hour));
      const diffMs = new Date(this.shift.real_end_hour).getTime() - new Date(this.shift.real_start_hour).getTime(); // Diferencia en milisegundos
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60)); // Horas completas
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Minutos restantes

      // Formatear en formato H:i
      const hoursFormatted = diffHours.toString().padStart(2, '0');
      const minutesFormatted = diffMinutes.toString().padStart(2, '0');

      this.workedHours = `${hoursFormatted}:${minutesFormatted} horas trabajadas`;
    } else {
      this.workedHours = ''; // Si no hay horas reales, no se muestra nada
    }
  }

  formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  formatDate(date: Date): string {
    // Arrays de días de la semana y meses en español
    const daysOfWeek = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
    const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

    // Obtener el día de la semana y el mes
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];

    // Construir el string formateado
    return `${dayOfWeek} ${dayOfMonth} de ${month}`;
  }
}
