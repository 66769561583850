<div class="bg-bg-dark">
  <div class="fixed w-full bg-bg-dark p-4">
    <button (click)="toggleDropdown()" class=" px-4 py-2 bg-bg-dark text-white rounded-full border border-gray-light">
      Ver turnos
    </button>
    <div *ngIf="isDropdownOpen" class="absolute left-4 mt-0 w-48 bg-bg-dark border border-gray-light rounded-lg shadow-lg">
      <button (click)="loadNextShifts()" class="block w-full px-4 py-2 text-left text-gray-light hover:bg-gray-100">
        Próximos turnos
      </button>
      <button (click)="loadPreviousShifts()" class="block w-full px-4 py-2 text-left text-gray-light hover:bg-gray-100">
        Turnos anteriores
      </button>
    </div>
  </div>
</div>
<div class="pt-16">
  <app-loader size="large" *ngIf="loading"></app-loader>
  <div *ngFor="let shift of shifts">
    <app-next-shift-card *ngIf="shift.isNext" [shift]="shift" (shiftUpdated)="onShiftUpdated()"></app-next-shift-card>
    <app-shift-card *ngIf="!shift.isNext" [shift]="shift" [pastShift]="pastShift"></app-shift-card>
  </div>
</div>
