import {CanActivateFn, Router} from '@angular/router';
import {AuthStateService} from "../services/auth/auth-state.service";
import { inject } from '@angular/core';
import {User} from "../../models/User";

export const loginGuard: CanActivateFn = (route, state) => {
  const userService = inject(AuthStateService);
  const router = inject(Router);
  const expectedRoles = route.data['roles'];
  // const user: User | null = userService.currentUserValue();

  if (userService.isLoggedIn()) {
    router.navigate(['dashboard']); // Redirige a la página de login si no está autenticado
    return false;
  }

  /*if (expectedRoles && user && !hasRole(user, expectedRoles)) {
    router.navigate(['/']); // Redirige a una página de acceso denegado si no tiene el rol adecuado
    return false;
  }*/

  return true;
};
