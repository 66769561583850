import { Component } from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {AuthService} from "../../../core/services/auth/auth.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {AuthFormLabelDirective} from "../../../shared/directives/auth/auth-form-label.directive";
import {AuthFormInputDirective} from "../../../shared/directives/auth/auth-form-input.directive";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {AuthStateService} from "../../../core/services/auth/auth-state.service";

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    RouterLink,
    AuthFormLabelDirective,
    AuthFormInputDirective
  ],
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent {
  email: string;
  password: string = "";
  password_confirmation: string = "";
  token: string;
  message: string = "";
  loading = false;

  constructor(private route: ActivatedRoute, private authService: AuthService, private toast: ToastrService, private spinner: NgxSpinnerService,
              private authStateService: AuthStateService, private router: Router) {
    this.token = this.route.snapshot.params['token'];
    this.email = this.route.snapshot.params['email'];
  }

  resetPassword() {
    const data = {
      email: this.email,
      password: this.password,
      password_confirmation: this.password_confirmation,
      token: this.token
    };
    this.spinner.show();
    this.loading = true;
    this.authService.resetPassword(data).subscribe({
      next: result => {
        this.toast.success('Contraseña restablecida con éxito.');
      },
      error: error => {
        console.log(error.error.errors.password[0]);
        this.toast.error(error.error.errors.password[0] ?? 'Error al restablecer la contraseña.');
        this.spinner.hide();
        this.loading = false;
      },
      complete: () => {
        this.spinner.hide();
        this.loading = false;
        this.authService.signIn({email: this.email, password: this.password}).subscribe({
          next: (value) => {
            if (value.data) {
              this.authStateService.setUser(value.data);
              this.router.navigate(['/dashboard'])
            }
          }
        })
      }
    })

    /*this.authService.resetPassword(data).subscribe(
      response => {
        this.message = 'Contraseña restablecida con éxito.';
      },
      error => {
        this.message = 'Error al restablecer la contraseña.';
      }
    );*/
  }
}
