import { Injectable } from "@angular/core";
import {HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse} from "@angular/common/http";
import {AuthStateService} from "../services/auth/auth-state.service";
import {catchError, throwError} from "rxjs";
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authStateService: AuthStateService, private router: Router) { }
  /**
   * Interceptor para añadir el token del usuario autenticado en cada request que se haga.
   *
   * Añade a todas las peticiones que se hagan desde la app el Bearer token del usuario si este está autenticado.
   *
   * @param req Request completa.
   * @param next Continuar con la petición.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authStateService.setUser(null);
          this.router.navigate(['/login']);
        }
        return throwError(() => error);
      })
    );
  }
}
