import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Shift} from "../../../models/Shift";
import {Response} from "../../../models/Response";
@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  constructor(private http: HttpClient) { }

  getUserShifts(userId: number): Observable<Response<Shift[]>> {
    const url = `${environment.API_URL}/shifts/user`;
    return this.http.get<Response<Shift[]>>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: new HttpParams().set('user_id', userId.toString())
    });
  }

  nextShifts(userId: number): Observable<Response<Shift[]>> {
    const url = `${environment.API_URL}/shifts/user/next`;
    return this.http.get<Response<Shift[]>>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: new HttpParams().set('user_id', userId.toString())
    });
  }

  // Método para registrar la entrada en un turno
  checkEnterShift(userId: number, shiftId: number): Observable<Response<any>> {
    const url = `${environment.API_URL}/shifts/check-enter-shift`;
    return this.http.post<Response<any>>(url, {
      user_id: userId,
      shift_id: shiftId
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }

  // Método para registrar la salida de un turno
  checkExitShift(userId: number, shiftId: number): Observable<Response<any>> {
    const url = `${environment.API_URL}/shifts/check-exit-shift`;
    return this.http.post<Response<any>>(url, {
      user_id: userId,
      shift_id: shiftId
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }

  checkShiftStatus(shiftId: number): Observable<{ isCheckedIn: boolean }> {
    const url = `${environment.API_URL}/check-shift-status`; // Asegúrate de tener una ruta para esto en la API
    return this.http.get<{ isCheckedIn: boolean }>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { shift_id: shiftId.toString() }
    });
  }
}
